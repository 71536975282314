// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-management-js": () => import("./../../../src/pages/about-us/management.js" /* webpackChunkName: "component---src-pages-about-us-management-js" */),
  "component---src-pages-about-us-references-js": () => import("./../../../src/pages/about-us/references.js" /* webpackChunkName: "component---src-pages-about-us-references-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-job-offers-js": () => import("./../../../src/pages/contact/job-offers.js" /* webpackChunkName: "component---src-pages-contact-job-offers-js" */),
  "component---src-pages-contact-request-support-js": () => import("./../../../src/pages/contact/request-support.js" /* webpackChunkName: "component---src-pages-contact-request-support-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */)
}

